import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class FirebaseService {
  dbUrl: string = "https://migracion-87efe.firebaseio.com/Cursos.json";
  dbUpdate: string = "https://migracion-87efe.firebaseio.com/Cursos";
  constructor(private http: HttpClient) {}

  sendData(array) {
    console.log(this.dbUrl);
    let body = JSON.stringify(array);
    const headers = new HttpHeaders({
      "Content-type": "application/json"
    });

    return this.http.post(this.dbUrl, body, { headers }).pipe(
      map(res => {
        return res;
      })
    );
  }

  sendData1() {
    const headers = new HttpHeaders({
      "Content-type": "application/json"
    });

    return this.http.get(this.dbUrl, { headers }).pipe(
      map(res => {
        return res;
      })
    );
  }

  updateData(id, bbody) {
    console.log(bbody);
    let body = JSON.stringify(bbody);
    const headers = new HttpHeaders({
      "Content-type": "application/json"
    });

    console.log(this.dbUpdate + "/" + id + ".json");
    return this.http
      .patch(this.dbUpdate + "/" + id + ".json", body, { headers })
      .pipe(
        map(res => {
          return res;
        })
      );
  }
}

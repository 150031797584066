import { Component } from "@angular/core";
import { FirebaseService } from "../app/services/firebase.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  title = "cursosadmin";
  busqueda = "";
  response: any[] = [];
  cursos: any[] = [];
  constructor(private firebase: FirebaseService) {
    let c1 = {
      COURSE_ID: "1",
      USER_ID: "2",
      DESP: "3"
    };
    this.firebase.sendData1().subscribe((data: any) => {
      this.cursos = data;
    });
  }

  buscarLogin(termino: string) {
    let Arr: any[] = [];
    termino = termino.toLowerCase();
    console.log(termino);
    for (let i = 1; i < 399; i++) {
      if (this.cursos[i].USER_ID == termino) {
        Arr.push(this.cursos[i]);
      }
    }
    this.response = Arr;
  }

  seleccionItem(evt: any) {
    if (evt.CONTINUA == "true") {
      evt.CONTINUA = "false";
    } else {
      evt.CONTINUA = "true";
    }
  }
  guardar() {
    for (let i = 1; i < this.response.length; i++) {
      this.firebase
        .updateData(this.response[i].ID, {
          CONTINUA: this.response[i].CONTINUA
        })
        .subscribe((data: any) => {});
    }
    alert("Los cambios fueron realizados muchas gracias.");
    this.response = [];
    this.busqueda = "";
  }
}
